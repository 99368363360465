/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesTrina: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Trina Guide & Review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_trina.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Trina Guide & Review</h1>
          <h2>A guide & review for Trina in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>17/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Trina" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="trina" enablePopover />
              </div>
              <p>
                A long time ago, in 2023, it used to feel like Shift Up did not
                know what they were doing when they released new units. The kits
                of units such as Viper, Cocoa, and most of the release units
                were quite weak all things considered. 2 years later, in 2025,
                it does seem like Shift Up finally has their game figured out.
                They know which units need what skill to be better in the
                current meta, and how to make units versatile across game modes,
                while trying to fill in gaps of what specific type of unit we
                lack. This is what they do with Trina. A unit who is not overly
                strong, and won’t brick your account if you skip her, but is
                still a very usable Nikke. Let’s dive into everything we know
                about her!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Trina is a niche supporter B2 healer, designed to assist and
                buff Electric allies with assault rifles. Well, she can only
                buff 1 Electric ally with Assault Rifles but she does do that
                extremely well. Currently, Scarlet and Moran are the only two
                meta characters worth using with her, with more emphasis on
                Scarlet. Hence, in her current state, Trina is only viable if
                you have a clear strategy for pairing her with these key
                characters. For instance, she can significantly boost Scarlet’s
                firepower and burst damage. If you plan to rely on Scarlet’s
                Burst Skill to clear a challenging Campaign stage, the
                Trina–Scarlet combo can be a rewarding choice.
              </p>
              <p>
                Alternatively, you can use Trina as a poor man’s Biscuit in PvP,
                boosting those Nikkes' survivability and enabling a stronger
                output. Her ATK DMG▲ will still be active, although her Burst
                Skill DMG▲ will not because it requires the presence of a
                certain number of Raptures. The ATK DMG, though, being over
                ~115% (with lvl 10 S2 + Burst Skill), is enough to allow Scarlet
                to one-shot everyone, given that the team is not protected by
                Noah, Bay, or Blanc. Not only that, Trina is a Clip RL.
              </p>
              <p>
                Keep in mind, Trina is still usable without Scarlet and Moran.
                Most of her buffs will deactivate, but at least you will still
                be able to utilize her heals, a small portion of her ATK DMG▲,
                and Burst Skill DMG▲. However, under these circumstances, she
                would easily be overshadowed by alternatives such as Crown.
              </p>
              <h5>Should I get this unit?</h5>
              <p>
                <i>
                  Before pulling Trina, ask yourself: Do you have Scarlet? Do
                  you see yourself investing into her?
                </i>
              </p>
              <p>
                In PvE content, Trina is almost ineffective without her, as her
                primary role is to boost Scarlet's performance and make her
                shine in specific scenarios, practically confirming Scarlet’s
                spot in lower-end teams in Electric-Weak Solo Raids. Without
                her, you might as well save your gems and fish her out of
                Ordinary using Wishlist. However, when paired with Scarlet,
                Trina offers tangible improvements: Her ATK DMG (and Burst Skill
                DMG▲) complements Scarlet's lack of ATK DMG and makes her Burst
                Skill satisfying again! In Campaign, this often ensures a
                screenwipe, allowing your team to gun down elites and bosses
                without worry. Yes, Trina can still be used even without
                Scarlet, but what makes her better than the other options? Not
                much.
              </p>
              <p>
                As a TL;DR, if you have Scarlet, we recommend pulling one copy
                of Trina. But, if you do not plan on using Scarlet, she is an
                easy skip for PvE-focused players.
              </p>
              <p>
                If your answer to the first question is no, we still have a
                saving grace second question: Do you love PvP? It is very true
                that Trina shines best with either Scarlet or Moran in PvP, and
                you definitely don't want to miss out on any Clip RL units as a
                PvP enthusiast.{' '}
                <strong>A must pull unit as far as PvP is concerned!</strong>
              </p>
              <p>
                <i>How about dupes?</i> Thankfully, none of Trina's essential
                buffs scales off her stat, which means{' '}
                <strong>one copy of Trina is fine for everyone</strong>. Her
                healing kind of does but small improvements in that area would
                be negligible. You don't even need to OL her because she doesn't
                contribute much damage herself, but you might need to for CP
                purposes in PvP.
              </p>
              <p>
                <i>Is she mileage worthy?</i> <strong>Definitely not</strong> in
                our opinion, at least for PvE-focused players. She will maybe
                have some uses in Campaign for Electric-weak stages, sure, but
                we are hard-pressed to find a spot for her in Raids outside of
                her own element, where she only allows Scarlet to shine. Her
                performance in PvP is stellar, though, especially when combined
                with Scarlet, where the 2s invulnerability + ATK DMG can confirm
                survival prior to Full Burst and instant wipes in super fast 2RL
                teams. Only competitive PvP players should consider using
                mileage on her.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Trina is a Clip RL unit, and she restores a lot of energy per
                shot (3.6%), slightly more than Anis. This makes her excellent
                for PvP and for Campaign if you need additional battery.
                Beginners who lack battery units can replace their Anis/Centi
                with Trina, who is objectively better than them in every aspect
                (except Shield).
              </p>
              <h5>Nature's Grace</h5>
              <blockquote>
                <p>■ Activates after Full Burst ends. Affects all allies.</p>
                <p>
                  Constantly recovers 4.06% of caster's final Max HP every 1 sec
                  for 5 sec.
                </p>
                <p>
                  ■ Activates when attacking with Full Charge. Affects the
                  target(s) if the HP of 2 ally unit(s) with the lowest HP
                  percentage is lower than 30%.
                </p>
                <p>Recovers 2.03% of caster's final Max HP as HP.</p>
                <p>
                  ■ Activates when attacking with Full Charge. Affects the
                  target(s) if the HP of 2 ally unit(s) with the lowest HP
                  percentage is lower than 50%.
                </p>
                <p>Recovers 1.57% of caster's final Max HP as HP.</p>
              </blockquote>
              <p>This skill has 2 simple components:</p>
              <ul>
                <li>
                  Teamwide HP regeneration for 5s upon <strong>exiting</strong>{' '}
                  Full Burst.
                </li>
                <li>
                  Additional HP recovery for <strong>up to</strong> 2 allies
                  when Full-Charging, if their HP is lower than 50% or 30%.
                </li>
              </ul>
              <p>
                The first one is self-explanatory. It's similar to Blanc's
                end-of-burst healing, which most of us are already familiar
                with. They are useful for fixing the team back up after taking
                AoE damage or any damage in general. It's reliable because the
                value is not distributed (everyone gets the same amount of
                healing).
              </p>

              <p>
                The second one has an ambiguous description, but the effect is
                pretty much similar to what you might expect. Whenever Trina
                shoots a fully charged shot, if there is any ally whose HP is
                below 50%, then she will heal that ally by 1.57%. This can apply
                to up to 2 allies and prioritize leftmost allies (P1 first
                before P2, etc). If they are below 30%, she will heal 2.03%
                more, adding up to 3.6% per shot at Level 10. However, this also
                means Trina's full charge will not heal anyone if everyone is
                healthy enough.
              </p>
              <h5>Peaceful Tree</h5>
              <blockquote>
                <p>
                  ■ Activates at the start of battle only if self survives.
                  Affects all Electric Code allies with rifles.
                </p>
                <p>
                  Max HP ▲ 44.98% of the caster's Max HP without restoring HP
                  constantly.
                </p>
                <p>
                  ■ Activates at the start of battle. Affects Electric Code ally
                  unit(s) with rifles on the leftmost side of the squad.
                </p>
                <p>Invulnerable for 2 sec.</p>
                <p>
                  ■ Activates when using Burst Skill. Affects 1 Electric Code
                  ally unit(s) with rifles on the leftmost side of the squad.
                </p>
                <p>Attack Damage ▲ 94.15% for 10 sec.</p>
                <p>Reloading Speed ▲ 50.82% for 10 sec.</p>
              </blockquote>
              <p>
                As long as Trina survives, any Electric allies with Assault
                Rifle in the team will{' '}
                <strong>have increased HP cap permanently</strong>. This
                synergizes well with Trina's S1, allowing her to heal them
                immediately at the start of the battle. It also pairs well with
                Scarlet in both PvE and PvP as she can benefit from her CRT DMG
                early and get healed more. Moran can also benefit from her DEF
                buffs early, making her tankier in PvP.
              </p>
              <p>
                In addition, they will also be invulnerable for 2s at the start
                of the battle. This is useless in PvE but significant in PvP
                where the first few seconds matter the most. Utilize this to
                prevent snipes from mainly Shotguns or to ensure that whoever is
                in P1/P5 survives till Full Burst.{' '}
                <strong>
                  Note that Scarlet’s counterattacks still trigger even when she
                  is invulnerable!
                </strong>
              </p>
              <Alert variant="primary">
                <p>
                  If you only have 1 Electric AR, that unit, regardless of
                  position, will receive this buff. If you use 2 Electric ARs in
                  the same team, you will have to pay attention to ordering as
                  the buff prioritizes the one leftmost.
                </p>
              </Alert>
              <p>
                If Trina uses her own Burst Skill, she will also grant that same
                ally unparalleled DPS and reload speed buffs. An empowered Moran
                will be able to heal more since she will get to deal more
                damage, whereas an empowered Scarlet will be able to maim the
                enemy with her auto-attacks, S2, and especially Burst Skill. The
                fact that Scarlet lacks personal ATK DMG and Trina gives just
                that is purely coincidental—surely not something that ShiftUp
                planned for. :)
              </p>
              <Alert variant="primary">
                <p>
                  Since the buff is provided upon Burst Skill usage, it
                  amplifies B3's Burst Skill's damage, such as Scarlet's wipe.
                  Huge! This is not including Trina's own Burst Skill's effects!
                </p>
              </Alert>
              <h5>Mother Forest</h5>
              <blockquote>
                <p>■ Affects all allies.</p>
                <p>
                  Max HP ▲ 20.14% of the caster's Max HP without restoring HP
                  for 10 sec.
                </p>
                <p>Attack Damage ▲ 20.9% for 10 sec.</p>
                <p>
                  ■ Activates when enemy count aside from Nikkes is 1. Affects
                  all allies.
                </p>
                <p>
                  Spread Roots: Burst Skill damage of skills with "Affects all
                  enemies" ▲ 435.6% for 5 sec.
                </p>
                <p>
                  ■ Activates when enemy count aside from Nikkes is more than 2.
                  Affects all allies.
                </p>
                <p>Changes Spread Roots to Wilted Roots.</p>
                <p>
                  Wilted Roots: Burst Skill damage of skills with "Affects all
                  enemies" ▲ 64.46% for 5 sec.
                </p>
                <p>■ Affects all Electric Code allies with rifles.</p>
                <p>Hit Rate ▲ 45.3% for 10 sec.</p>
                <p>Max Ammunition Capacity ▲ 20 round(s) for 10 sec.</p>
              </blockquote>
              <p>This skill has 3 effects: </p>
              <ul>
                <li>
                  The first effect applies to all allies regardless of their
                  element or weapon type. It increases their Max HP without
                  restoring HP and mildly increases ATK DMG for 10s. This is
                  good for enhancing certain abilities such as heals and other
                  effects that scale off Max HP (like Cinderella's or
                  XMaiden's).
                </li>
                <li>
                  The second effect applies to all allies only if the number of
                  Raptures present exceeds a certain number, which means it does
                  not activate in PvP.
                </li>
                <ul>
                  <li>
                    When there is only 1, the B3's Burst Skill damage, if its
                    description contains "Affects all enemies.", will be
                    enhanced massively, so it can better handle single targets
                    even with its low even output. This allows Scarlet to help
                    snipe elites or bosses in Campaign stages that are left
                    alone, usually after you've eradicated all other mobs—a
                    common occurrence.
                  </li>
                  <li>
                    She also does not buff all burst skills which have "Affects
                    all enemies." written. They also need to have thet term
                    “.... Burst Skill Damage.”
                  </li>
                  <li>
                    If there are more than 1, however, the enhancement still
                    applies, but the effect will be much weaker. It's still
                    noticeable and definitely helps with ensuring full screen
                    wipes in Campaign. Here's a tip: if there are only 2 mobs
                    alive, and one of them has low HP, kill them first before
                    starting your B2-B3 chain.
                  </li>
                </ul>
                <li>
                  The third effect is a special effect that only applies to
                  Electric allies with Assault Rifles,{' '}
                  <strong>
                    including AR units with skill(s) that alter weapon types
                    like Moran
                  </strong>
                  . It grants Hit Rate, which improves accuracy for landing core
                  shots against medium-to-far bosses and especially mobs outside
                  AR's comfortable range, and Max Ammo, which is particularly
                  valuable to Scarlet due to her low default base ammo count.
                </li>
              </ul>
              <p>
                By visiting Union Shooting Range and practicing against mobs,
                you will be able to witness first-hand how much impact the Hit
                Rate gives. Turn on damage numbers and observe how much more
                damage Scarlet deals within 10 seconds! No, it's not a
                game-changer in the sense that Scarlet’s shots turn into a laser
                beam, but it helps lower the time taken to kill in Campaign,
                where every millisecond matters. It's kill or be killed.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="s">S</strong> | Campaign (HD)
                - <strong className="a">A</strong>
              </h5>
              <p>
                Trina can help players clear Campaign stages reliant on AoE
                wipes. However, she faces one glaring issue—she is heavily
                reliant on Scarlet. Even though she is usable without her, she
                is limited to being useful only for AoE screen clear units like
                Rei Ayanami (Tentative Name) or mediocre one trick units such as
                Maid Privaty, Bunny Soda, or Harran. That said, her Hit Rate▲
                allows Scarlet to land core shots more, which significantly
                boosts her follow-up auto-attack damage, and her ATK DMG▲ +
                Burst Skill DMG▲ can triple her Burst Skill DMG—perfect for
                Electric-weak Stages!
              </p>
              <p>
                While that sounds remarkable, with the recent introduction of
                capable Nikkes, it is hard to justify slotting her in Campaign
                in place of a more competent B2 like Crown. Thus, we would
                consider her a luxury. If you plan to adopt a 1-2-2 setup,
                however, you can technically use Trina & Crown together, but do
                keep in mind that to take advantage of Trina's amazing buffs on
                Electric ARs, she needs to be the one bursting. Crown's Burst
                Skill can be used on off-rotations.
              </p>
              <p>
                Fun fact: Thanks to Trina’s post-burst healing, Crown’s Skill 2
                can passively activate and strengthen Burst Skill attacks in the
                second rotation onward.
              </p>
              <p>
                Players with an incomplete roster especially in the B2
                department can use Trina as a temporary B2. She is still better
                than most options and definitely helps with Missilis Tower if
                you don’t have Tia+Naga.
              </p>
              <p>
                <i>
                  Please note that Trina's Campaign (High Deficit) Rating is in
                  accordance with a Campaign Tier List revision that is
                  currently in the works. In the current version of the Tier
                  List as of this review's release, she is S tier.
                </i>
              </p>
              <h5>
                Bossing - <strong className="s">S</strong>
              </h5>
              <p>
                Trina is by no means a bad unit, but her impact in Bossing is
                inconsistent due to her skill restrictions. Trina + Scarlet is
                obviously the only reasonable duo we can assume here since Moran
                is not a PvE character. Together, they are powerful, but are
                they more powerful than other combinations players with a
                complete roster can come up with? No. Despite that, we can
                foresee the possibility of her being used in Electric-weak
                Raids, especially Electric-Weak Solo Raids, or when you need to
                run multiple times against an Electric-Weak boss in Union Raid,
                which is not optimal but sometimes you have no choice. We,
                however, don’t expect to see her in Neutral Element Raids at the
                top level.
              </p>
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <p>
                When paired with Scarlet or Moran, Trina can act as a makeshift
                Biscuit, providing 2s invulnerability right at the start of the
                battle. This can prevent them from being sniped, although they
                would still be vulnerable after 2s have passed, so the stalling
                potential is weaker than Biscuit. However, it can also be used
                to hard-counter SG/MG teams by placing Scarlet in P1/P5 and
                using Trina to deny damage from the first 2 seconds, allowing
                Scarlet to retaliate in peace without worrying about dying to
                well-built SG with 4 ATK + 4 ELE.
              </p>
              <p>
                Trina can also amplify their output through her S2 + Burst Skill
                buffs, giving more than 110% ATK DMG, Reload Speed▲, Ammo Up,
                and non-restoring Max HP. This allows Scarlet to deal much more
                damage with her Burst and enjoy her CRIT DMG buff early, and
                Moran to benefit from her DEF buffs early, making her overall
                tankier.
              </p>
              <p>
                However, do note that the Burst Skill DMG▲ for "Affects all
                allies..." does not activate in PvP, so she is not a universal
                buffer (well, she is, but she only gives the first part of her
                Burst Skill, which is not that strong alone). She can fill in as
                a healer, but her offensive buffs when not paired with Scarlet
                or Moran will be absent.
              </p>
              <p>
                That said, it is still justified to play Trina even without her
                pairs. Why is that? Well, she is a Clip RL, and we know how
                broken Clip RLs are for PvP! She can slot in any team as a
                replacement of Centi, Rumani, Jackal, and even HelmT in most
                team builds. A must pull unit for PvP. ‘Nuff said!
              </p>
              <p>
                <i>Check PvP Team Compositions for some ideas!</i>
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                For a Supporter who doesn’t deal damage, you don’t need to level
                her gear at all… until you need to OL her gear for CP padding,
                because OL options give a lot of CP. Just spend 1 Rock per gear
                and call it a day.
              </p>
              <p>
                Charge Speed is beneficial in PvP because it allows your team to
                Full Burst faster. On the other hand, Max Ammo is more for PvE,
                allowing you to spam for burst gen more comfortably.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Charge Speed
                </li>
                <li>
                  <strong>Passable:</strong> 1× Max Ammo
                </li>
                <li>
                  <strong>Priority:</strong> Medium
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                Her skills are <i>mostly</i> meant for Scarlet, so it’s{' '}
                <i>mostly</i> meaningless if you don’t have Scarlet or your
                Scarlet is not good enough. Levels can help with CP padding,
                though.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 4</strong>
                </li>
                <ul>
                  <li>
                    Decent healing skill but nothing else. Just like any other
                    healing skills, don’t invest too much here.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 7/10</strong>
                </li>
                <ul>
                  <li>
                    This skill gives a lot of Attack Damage, but the scaling is
                    average. You should stop at Lv 7 if you plan to use her only
                    in PvP. That much is guaranteed to allow Scarlet to kill
                    everyone outside Noah/Bay/CP Deficit comps. If you are a
                    competitive player, maxing out is the play.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:4/7/10</strong>
                </li>
                <ul>
                  <li>
                    Same reason with S2. It gives a lot of DMG for Scarlet’s
                    Burst, but what’s your Scarlet’s Burst level? We recommend
                    just matching that level. The buff is quite pale—no need to
                    spend more than 4 outside PvP (Burst + S2 = 99% Attack
                    Damage already, enough to confirm kill!). If resources are
                    scarce and PvP is your favorite game mode, prioritize S2
                    first since Burst Skill DMG▲ does not activate in PvP.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="trina" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                For PvE, use your highest level cube or Resilience/Bastion to
                make spamming for burst gen with her easier.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/cube_8_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_10_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_11_sm.webp"
                alt="Guide"
              />
              <p>
                But, for PvP min-maxing, Healing/Vigor Cube is the most optimal,
                because she’s a healer. If you put her in S2, she may be killed
                by wild Laplace (due to ELE advantage), so Tempering can do too.
              </p>
              <h5>Doll Investments</h5>
              <p>
                Doll on her is optional but recommended as it gives a lot of CP
                and damage reduction, which helps in PVP. SR 0 or 5 is
                sufficient.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Scarlet Treasure</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                The new general team composition for PvE whenever Scarlet is
                relevant. B3 can be any unit that can do the job for the stage
                or boss, but will mostly depend on game mode. RRH, Liter, and
                Volume are popular pairings alongside Scarlet for B1-CDR. A
                Doro-Priv team to allow Scarlet to achieve 100% Reload Speed is
                also not a bad idea if there are Boss Parts.
              </p>
              <h5>Team #2: Electric Wombo Combo #73</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                A highly competitive bossing team, where we utilise Trina’s
                unique Max HP buff to buff Cinderella and XMaiden. This team may
                also be the key team for Infinite Mode in Overclock for
                Electric, whenever it comes.
              </p>
              <h5>Team #3: Off-Burst Menace!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Scarlet can also be used as an off-burst unit in bossing. On
                occasions when there is no core, S.Anis gains more damage as
                Main B3 and hence it would be ideal to just use Scarlet as an
                off-burst unit especially if the player does not have XMaiden.
              </p>
              <h5>Team #4: Campaign Shenanigans</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1 CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                The best team for Scarlet in PvE is still with Crown. In
                Campaign, if she is relevant or if you want to use her, simply
                use Trina Burst with Scarlet and Crown Burst with the other B3.
              </p>
              <h5>Team #5: Trina the Buffer!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley-wille"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="privaty" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A team for recently released and easily available units for new
                players. Not an ideal team but very effective for both Campaign
                and Bossing.
              </p>
              <h5>Team #6: Syuen’s Minions</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                A team for Missilis Tower for players who do not have Tia Naga.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: 2RL - Slash or Die</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace-treasure"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rumani" enablePopover />
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                SPEED! That is what this team is about. A hyper-buffed Scarlet
                nuke to end them all! Only blockable by fast enough Noah, Bay,
                or Blanc teams. Big emphasis on speed!
              </p>
              <h5>Team #2: Simple 3RL Nuke</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Really no explanation needed, we tried to fit in units who are
                usually invested to make a high CP team. This is a very simple
                3RL high-damage nuke team. It has a lot of counters but can beat
                teams where players decide to CP-stack PvE units hopefully.
              </p>
              <h5>Team #3: Prepare for Nukes! And Make Them double!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rosanna" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team needs immense investment to work properly as a 2RL
                hyper-fast nuke. Both Scarlet and Rosanna need to be SR15 to
                match Helm Treasure’s ATK stat. Both of them, in fact, need to
                beat Helm’s ATK stat so that they can get the Jackal link over
                Helm. This way, Rosanna and Scarlet are now ready to devastate
                the whole enemy team!
              </p>
              <h5>Team #4: Moran the Absolute TANK! </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="nero" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="moran" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A very strong stall team! Nero simply just blocks everything in
                P1. The usually speed of this team is 3RL. However, with 17%
                charge speed on both Trina and Rapunzel, this team can reach
                2.5RL in speed! That is insanely fast for a strong stall team
                like this one!
              </p>
              <h5>Team #5: Anti-SG on Attack</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="trina" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Putting Scarlet alone in P5 and inserting Trina in the team can
                allow her to safely tank Shotguns aiming at P5 (default target
                priority for defending SGs). In this position, she functions
                similar to Makima but better since she will be able to harass
                the enemy SGs with counter-attacks. This opens up your
                Makima/Jackal/Biscuit, allowing you to use them for other teams
                instead!
              </p>
              <p>
                Trina’s position in this team is flexible. You can also do
                something similar on Defense by placing Scarlet in P1 since
                Attacking SGs will focus in P1, but defense teams are easily
                countered, so practice caution.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Insane attack damage and reload speed buff for one
                          Electric AR unit.
                        </li>
                        <li>
                          Provides decent Hit Rate and Max Ammo buffs to all
                          electric AR allies.
                        </li>
                        <li>
                          Great buffer for AoE screen wipes, but only for
                          specific units.
                        </li>
                        <li>Clip RL - has insane burst generation.</li>
                        <li>Decent heal after full burst ends.</li>
                        <li>
                          Provides a short invulnerability for Electric ARs,
                          strong use-case in PvP.
                        </li>
                        <li>Scarlet’s Favorite Item basically.</li>
                        <li>Do you know: They are called Tits. Literally.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Niche Supporter - only strongly buffs Electric ARs.
                        </li>
                        <li>
                          Only Scarlet and Moran are currently relevant enough
                          units to use with her as of her release.
                        </li>
                        <li>Attack Damage buff is only for one ally.</li>
                        <li>
                          AoE Screen Wipe multiplier is severely reduced if more
                          than one enemy rapture is on screen.
                        </li>
                        <li>
                          Healing on full charged shots requires units to be
                          below 50% HP, and can only heal units up till 50% HP.
                          The rest of the HP only partially fills after full
                          burst ends.
                        </li>
                        <li>
                          Invulnerability duration is too short, Scarlet can
                          often still die even before 2RL.
                        </li>
                        <li>
                          Apparently her costume is that of a birch tree, and
                          not of a cow. No milker joke :(
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesTrina;

export const Head: React.FC = () => (
  <Seo
    title="Trina Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Trina in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
